import React, {useEffect, useState} from 'react'
import {useAuth} from "../../Context"
import gql from "graphql-tag"
import {isToken} from "../../utils/scripts"
import {decodeToken} from "../../utils/scripts"

const AppFooter = () => {
    const {origin} = window.location
    const {client, auth, toast} = useAuth()
    const [pack, setPack] = useState('')

    useEffect(async () => {
        if (auth && auth.authentication) {
            const fetchData = async () => {
                const {id, exp} = decodeToken(auth.authentication)
                const currentTimestamp = Math.floor(Date.now() / 1000)
                if (exp < currentTimestamp) return

                try {
                    const response = await client.query({
                        query: gql`query getCurrentPacks($id: Int!) {
                        getCurrentPack(id: $id)
                    }`,
                        context: {headers: {isAuth: isToken()}},
                        variables: {id},
                        fetchPolicy: 'no-cache'
                    })
                    const {success, result} = response.data.getCurrentPack

                    if (success)
                        setPack(result)
                    else
                        setPack('FREE')

                } catch (error) {
                    toast.error(error.message, {autoClose: 2000})
                }
            }

            await fetchData()
        }
    }, [])


    let footer = `copyright © ${new Date().getFullYear()}`
    switch (origin) {
        case 'https://www.grupseld.com':
        case 'https://grupseld.com':
        case 'https://www.app.perucontable.net':
        case 'https://app.perucontable.net':
            footer = `© ${new Date().getFullYear()}. Desarrollado y Soportado por`
            break
    }

    return (
        <div className="app-footer">
            <div className="app-footer__inner">
                <div className="app-footer-left">
                    <span>{footer}<a href="https://alternativascontables.pe" target="_blank"> Alternativas Contables S.R.L.</a></span>
                </div>
                <div className="app-footer-right">
                    {
                        pack ? (
                            pack.paquete_id === 40 || pack.paquete_id === 33 ?
                                (
                                    <span><strong style={{color: 'green'}}>Estás usando la versión PRO 🚀</strong> - Tu paquete vence el <strong>{pack.end_at}</strong></span>
                                ) : (
                                    <span><strong style={{color: 'red'}}>Estás usando la versión GRATUITA</strong> - Adquiere tu paquete PRO 🚀 <strong><a
                                        href="/admin/pagos"> AQUÍ</a></strong></span>
                                )
                        ) : (
                            ''
                        )
                    }
                </div>
                <div className="app-footer-right">
                    v<strong>2.01.2205</strong>
                </div>
            </div>
        </div>
    )
}
export default AppFooter
