import React, {Fragment, useState, useEffect, useLayoutEffect} from "react"
import {Link, Redirect} from "react-router-dom"
import Slider from "react-slick"
import {Col, Row, Form, FormGroup, Label, Input} from 'reactstrap'
import {faUser, faLock, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import gql from 'graphql-tag'
import LaddaButton, {EXPAND_RIGHT} from 'react-ladda'

// import bg1 from '../../assets/utils/images/originals/bg1.jpg'
// import bg2 from '../../assets/utils/images/originals/bg2.jpg'
// import bg3 from '../../assets/utils/images/originals/bg3.jpg'

import bg_1 from '../../assets/utils/images/originals/bg_1.png'
import bg_2 from '../../assets/utils/images/originals/bg_2.png'
import bg_3 from '../../assets/utils/images/originals/bg_3.png'
import bg_4 from '../../assets/utils/images/originals/bg_4.png'
import bg_5 from '../../assets/utils/images/originals/bg_5.png'
import bg_6 from '../../assets/utils/images/originals/bg_6.png'

import {useAuth} from "../../Context"
import {isToken} from "../../utils/scripts"

const Login = () => {
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true
    }
    const {auth, client, setClient, toast} = useAuth()
    const [user, setUser] = useState({
        email: '',
        password: ''
    })
    const [expRight, setExpRight] = useState(false)

    const [showPassword, setShowPassword] = useState(false)

    const handleChange = ({target}) => {
        const {name, value} = target
        setUser({
            ...user,
            [name]: value
        })
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    const postLogin = () => {
        const {email, password} = user
        setExpRight(true)
        client.query({
            query: gql`query createToken($email: String!, $password: String!) {
              login(email: $email, password: $password) {
                authentication
                layout
              }
            }`,
            context: {headers: {isAuth: isToken()}},
            variables: {
                email,
                password
            },
            fetchPolicy: "no-cache"
        }).then(response => {
            const {login} = response.data
            setExpRight(false)
            if (login === null) {
                toast.error('Credenciales incorrectas', {autoClose: 2000})
                return
            }
            const {authentication} = login
            if (authentication === 'verify') {
                toast.error('Aún no ha verificado su email!', {autoClose: 10000})
                return
            }
            setClient(login)
        }).catch(({message}) => {
            toast.error(message, {autoClose: 2000})
            setExpRight(false)
        })
    }

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter")
                postLogin()
        }
        document.addEventListener("keydown", listener)
        return () => {
            document.removeEventListener("keydown", listener)
        }
    }, [user])

    useLayoutEffect(() => {
        if(!(auth.authentication)){
            localStorage.clear()
            sessionStorage.clear()
            document.cookie.split(";").forEach(function (c) {
                document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            })

            localStorage.setItem('Auth', JSON.stringify({}))
            localStorage.setItem('Auth2', JSON.stringify({}))
            localStorage.setItem('Company', JSON.stringify({}))
            localStorage.setItem('localempresa', JSON.stringify({}))
        }

    }, [])

    return (
        auth.authentication ?
            <Redirect to={'/home'}/>
            :
            <Fragment>
                <div className="h-100">
                    <Row className="h-100 no-gutters">
                        <Col lg="8" className="d-none d-lg-block">
                            <div className="slider-light">
                                <Slider  {...settings}>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{backgroundImage: 'url(' + bg_1 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'left'}}>
                                            <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 'xxx-large' }}>Sistema automatizado de SIRE</h1>
                                            <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 'xxx-large' }}>con API WEB de SUNAT</h1>
                                            <p style={{ fontFamily: 'Arial, sans-serif',  fontSize: 'large'}}>Módulo automatizado de SIRE para procesar, validar y enviar RVIE y RCE a SUNAT de
                                                forma simple, segura y automática mediante el API WEB del SIRE</p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{backgroundImage: 'url(' + bg_2 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'left'}}>
                                            <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 'xxx-large' }}>Proceso Simplificado</h1>
                                            <p style={{ fontFamily: 'Arial, sans-serif',  fontSize: 'large'}}>Proceso de validación y comparación entre lo contable y lo propuesto por SUNAT; asícomo, el envío de los registros en simples pasos.</p>
                                            <p style={{ fontFamily: 'Arial, sans-serif',  fontSize: 'large'}}>(Solo requiere subir TXT de tu sistema contable)</p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{backgroundImage: 'url(' + bg_3 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'left'}}>
                                            <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 'xxx-large' }}>Decide basado en la comparación contable</h1>
                                            <p style={{ fontFamily: 'Arial, sans-serif',  fontSize: 'large'}}>Confronta tus datos con la propuesta de SUNAT</p>
                                            <p style={{ fontFamily: 'Arial, sans-serif',  fontSize: 'large'}}>Determina diferencias entre el detalle de los registros contables y los propuestos.</p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-left align-items-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{backgroundImage: 'url(' + bg_4 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'left'}}>
                                            <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 'xxx-large' }}>Envía directo a SUNAT y descarga tus constancias</h1>
                                            <p style={{ fontFamily: 'Arial, sans-serif',  fontSize: 'large'}}>Genera y/o envía tus registros contables directo a SUNAT, descarga tus constancias y consulta tu historial de registros anotados.</p>

                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-left align-items-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{backgroundImage: 'url(' + bg_5 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'left'}}>
                                            <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 'xxx-large' }}>Consulta de Validez de CPE</h1>
                                            <p style={{ fontFamily: 'Arial, sans-serif',  fontSize: 'large'}}>Consulte la validez de boletas, facturas y sus respectivas notas electrónicas.</p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-left align-items-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{backgroundImage: 'url(' + bg_6 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'left'}}>
                                            <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 'xxx-large' }}>Consulta DNI, RUC y EsSalud</h1>
                                            <p style={{ fontFamily: 'Arial, sans-serif',  fontSize: 'large'}}>Consultas de reniec, sunat y essalud sin capcha.</p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </Col>
                        <Col lg="4" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                                <div className="text-center">
                                    {/*<img src="/logo.png" alt=""/>*/}
                                    <img style={{width: '60%', marginBottom: '20px'}} src="/new_logo.png" alt=""/>
                                    <h4 className="mb-0">
                                        <div style={{fontWeight: "bold", fontSize: "xx-large", color: "black"}}>Bienvenido,</div>
                                        <span>Inicia sesión en tu cuenta.</span>
                                    </h4>
                                </div>
                                <Row className="divider"/>
                                <Form>
                                    <Row form>
                                        <Col md={12}>
                                            <FormGroup>
                                                {/*<Label for="exampleEmail"  style={{fontWeight: "bold", fontSize: "medium"}}>Email</Label>*/}
                                                <FontAwesomeIcon icon={faUser} style={{ position: 'absolute', left: '1.2rem', top: '20%', transform: 'translateY(0%)', color: '#aaa', fontSize: '1.2rem' }} />
                                                <Input type="email" name="email" style={{ paddingLeft: '2.75rem' }} value={user.email} onChange={handleChange}/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} style={{ marginTop: '1rem' }}>
                                            <FormGroup style={{ position: 'relative' }}>
                                                <FontAwesomeIcon icon={faLock} style={{ position: 'absolute', left: '0.9rem', top: '20%', transform: 'translateY(0%)', color: '#aaa', fontSize: '1.2rem' }} />
                                                <Input type={showPassword ? 'text' : 'password'} name="password" style={{ paddingLeft: '2.75rem' }} value={user.password} onChange={handleChange} />
                                                <FontAwesomeIcon
                                                    icon={showPassword ? faEyeSlash : faEye}
                                                    onClick={togglePasswordVisibility}
                                                    style={{ position: 'absolute', right: '1.2rem', top: '25%', transform: 'translateY(0%)', color: '#aaa', fontSize: '1.2rem', cursor: 'pointer' }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup style={{ marginTop: '1rem' }} check>
                                        <Input type="checkbox" name="check" id="exampleCheck"/>
                                        <Label for="exampleCheck" check>Mantenme conectado</Label>
                                    </FormGroup>
                                </Form>
                                <Row className="divider"/>
                                <div className="d-flex align-items-center">
                                    <div className="ml-auto">
                                        <Link to="/password/reset" className="btn-lg btn btn-link">¿Olvidó su
                                            contraseña?</Link>
                                        <LaddaButton style={{ backgroundColor: '#1f3c7e', borderColor: '#1f3c7e' }} className="btn btn-pill btn-primary" loading={expRight}
                                                     onClick={postLogin}
                                                     data-style={EXPAND_RIGHT}>
                                            <FontAwesomeIcon icon={faUser}/> Ingresar
                                        </LaddaButton>
                                    </div>
                                </div>

                                <h6 className="mt-3">
                                    ¿Sin cuenta?{' '}
                                    <Link to={'/register'} style={{ color: '#1f3c7e' }}  className="text-primary">Regístrate ahora</Link>
                                </h6>
                            </Col>
                        </Col>
                    </Row>
                </div>
            </Fragment>
    )
}

export default Login