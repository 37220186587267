import React, {Fragment, useState, useEffect} from "react"
import {Link} from "react-router-dom"
import {Col, Row, Button, Form, FormGroup, Label, Input} from "reactstrap"
import Slider from "react-slick"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPaperPlane, faUserPlus, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons"
import gql from "graphql-tag"
import LaddaButton, {EXPAND_RIGHT} from "react-ladda"
// import bg3 from '../../assets/utils/images/originals/bg1.jpg'
// import bg1 from "../../assets/utils/images/originals/bg2.jpg"
// import bg2 from "../../assets/utils/images/originals/bg3.jpg"

import bg_1 from '../../assets/utils/images/originals/bg_1.png'
import bg_2 from '../../assets/utils/images/originals/bg_2.png'
import bg_3 from '../../assets/utils/images/originals/bg_3.png'
import bg_4 from '../../assets/utils/images/originals/bg_4.png'
import bg_5 from '../../assets/utils/images/originals/bg_5.png'
import bg_6 from '../../assets/utils/images/originals/bg_6.png'

import {useAuth} from "../../Context"
import {isToken} from "../../utils/scripts"

const Register = () => {
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true
    }

    const {client, removeAllToasts, toast} = useAuth()

    const [user, setUser] = useState({
        email: '',
        name: '',
        apellido_paterno: '',
        apelido_materno: '',
        password: '',
        confirm_password: '',
        telefono: '',
    })
    const [registrado, setRegistrado] = useState(false)
    const [expRight, setExpRight] = useState(false)

    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const handleChange = e => {
        const {name, value} = e.target
        setUser({
            ...user,
            [name]: value
        })
    }

    const postRegister = () => {
        const {email, name, apellido_paterno, apelido_materno, password, confirm_password, telefono} = user

        if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email)) {
            toast.warning(`La dirección de email "${email}" es incorrecta.`, {autoClose: 2000})
            return
        }

        if (name === '') {
            toast.warning('Ingrese un nobre de Usuario', {autoClose: 2000})
            return
        }

        if (telefono.length < 9) {
            toast.warning('Ingrese un número del teléfono de contacto válido', {autoClose: 2000})
            return
        }

        if (password.length < 6 || password.length > 20) {
            toast.warning('La contraseña debe tener de 5 a 20 caracteres', {autoClose: 5000})
            return
        }

        if (password !== confirm_password) {
            toast.warning('Las contraseñas no son iguales', {autoClose: 5000})
            setUser({
                ...user,
                password: '',
                confirm_password: ''
            })
            return
        }

        setExpRight(true)
        const {origin} = window.location
        let partner_id = 1
        switch (origin) {
            case 'https://www.grupseld.com':
            case 'https://grupseld.com':
                partner_id = 2
                break
            case 'https://www.app.perucontable.net':
            case 'https://app.perucontable.net':
                partner_id = 3
                break
        }

        client.mutate({
            mutation: gql`mutation createUser($name: String!, $apellido_paterno: String!, $apelido_materno: String!, $email: String!, $password: String!, $telefono: String!, $partner_id: Int!, $source: String) {
                  createUsuario(name: $name, apellido_paterno: $apellido_paterno, apelido_materno: $apelido_materno, email: $email, password: $password, telefono: $telefono, partner_id: $partner_id, source: $source) {
                    id
                  }
                }`,
            context: {headers: {isAuth: isToken()}},
            variables: {
                name, apellido_paterno, apelido_materno, email, password, telefono, partner_id, source: 'sireweb'
            }
        }).then(response => {
            const {createUsuario} = response.data

            setExpRight(false)

            if (createUsuario === null) {
                toast.warning(`El correo "${email}" ya esta en uso`, {autoClose: 5000})
                return
            }

            setRegistrado(true)
        }).catch(({message}) => {
            toast.warning(message, {autoClose: 2000})
            setExpRight(false)
        })
    }

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter")
                postRegister()
        }
        document.addEventListener("keydown", listener)
        return () => {
            document.removeEventListener("keydown", listener)
        }
    }, [user])

    const postVerify = () => {
        toast.success('Reenviando Email...')
        client.query({
            query: gql`query resetVerify($email: String!){
                  verify(email: $email) {
                    id, status
                  }
                }`,
            context: {headers: {isAuth: isToken()}},
            variables: {
                email: user.email
            },
            fetchPolicy: "no-cache"
        }).then(response => {
            const {verify} = response.data

            if (verify === null) {
                toast.error('¡Ingrese un email Válido!', {autoClose: 10000})
                return
            }
            removeAllToasts()
            if (verify && verify.status)
                toast.success('¡Te hemos enviado por correo la confirmación de cuenta!', {autoClose: 10000})
            else
                toast.error('Se ha producido un error!!!', {autoClose: 2000})


        }).catch(({message}) => {
            removeAllToasts()
            toast.error(message, {autoClose: 2000})
        })
    }

    return (
        registrado ?
            <Fragment>
                <div className="h-100">
                    <Row className="h-100 no-gutters">
                        <Col lg="7" md="12"
                             className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center">
                            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                                <div className="text-center">
                                    <img style={{width: '60%', marginBottom: '20px'}} src="/new_logo.png" alt=""/>
                                </div>
                                <h4>
                                    <div>Bienvenido,</div>
                                    <br/>
                                    <p style={{textAlign: 'justify'}}>Se ha envido un correo electrónico a la dirección
                                        que has registrado
                                        ({user.email}), por favor ingresa a tu bandeja y confirma tu cuenta, en caso de
                                        no existir ningún correo, revisa tu span o pulsa en el boton reenviar para que
                                        te reenviemos el correo.</p>
                                    <br/>
                                    <p style={{textAlign: 'justify', color: '#dc3545'}}><strong>NOTA: Revise su bandeja
                                        Spam o No deseados.</strong></p>

                                    <span className="text-muted">Mientras no confirmes tu cuenta no podras usar ningún servicio gratuito.</span>
                                </h4>
                                <Col md={12} className="text-center">
                                    <Row className="divider"/>
                                    <Button color="primary" size="lg" onClick={postVerify}>
                                        <FontAwesomeIcon icon={faPaperPlane}/> Reenviar Correo de Verificación
                                    </Button>
                                </Col>
                            </Col>
                        </Col>
                        <Col lg="5" className="d-none d-lg-block">
                            <div className="slider-light">
                                <Slider  {...settings}>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg"
                                             style={{backgroundImage: 'url(' + bg_1 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'center'}}>
                                            <h1 style={{
                                                fontFamily: 'Arial, sans-serif',
                                                fontWeight: 'bold',
                                                fontSize: 'xx-large'
                                            }}>Sistema automatizado de SIRE</h1>
                                            <h1 style={{
                                                fontFamily: 'Arial, sans-serif',
                                                fontWeight: 'bold',
                                                fontSize: 'xx-large'
                                            }}>con API WEB de SUNAT</h1>
                                            <p style={{fontFamily: 'Arial, sans-serif', fontSize: 'large'}}>Módulo
                                                automatizado de SIRE para procesar, validar y enviar RVIE y RCE a SUNAT
                                                de
                                                forma simple, segura y automática mediante el API WEB del SIRE</p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg"
                                             style={{backgroundImage: 'url(' + bg_2 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'center'}}>
                                            <h1 style={{
                                                fontFamily: 'Arial, sans-serif',
                                                fontWeight: 'bold',
                                                fontSize: 'xx-large'
                                            }}>Proceso Simplificado</h1>
                                            <p style={{fontFamily: 'Arial, sans-serif', fontSize: 'large'}}>Proceso de
                                                validación y comparación entre lo contable y lo propuesto por SUNAT;
                                                asícomo, el envío de los registros en simples pasos.</p>
                                            <p style={{fontFamily: 'Arial, sans-serif', fontSize: 'large'}}>(Solo
                                                requiere subir TXT de tu sistema contable)</p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg"
                                             style={{backgroundImage: 'url(' + bg_3 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'center'}}>
                                            <h1 style={{
                                                fontFamily: 'Arial, sans-serif',
                                                fontWeight: 'bold',
                                                fontSize: 'xx-large'
                                            }}>Decide basado en la comparación contable</h1>
                                            <p style={{fontFamily: 'Arial, sans-serif', fontSize: 'large'}}>Confronta
                                                tus datos con la propuesta de SUNAT</p>
                                            <p style={{fontFamily: 'Arial, sans-serif', fontSize: 'large'}}>Determina
                                                diferencias entre el detalle de los registros contables y los
                                                propuestos.</p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-left align-items-center bg-premium-dark">
                                        <div className="slide-img-bg"
                                             style={{backgroundImage: 'url(' + bg_4 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'center'}}>
                                            <h1 style={{
                                                fontFamily: 'Arial, sans-serif',
                                                fontWeight: 'bold',
                                                fontSize: 'xx-large'
                                            }}>Envía directo a SUNAT y descarga tus constancias</h1>
                                            <p style={{fontFamily: 'Arial, sans-serif', fontSize: 'large'}}>Genera y/o
                                                envía tus registros contables directo a SUNAT, descarga tus constancias
                                                y consulta tu historial de registros anotados.</p>

                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg"
                                             style={{backgroundImage: 'url(' + bg_5 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'center'}}>
                                            <h1 style={{
                                                fontFamily: 'Arial, sans-serif',
                                                fontWeight: 'bold',
                                                fontSize: 'xx-large'
                                            }}>Consulta de Validez de CPE</h1>
                                            <p style={{fontFamily: 'Arial, sans-serif', fontSize: 'large'}}>Consulte la
                                                validez de boletas, facturas y sus respectivas notas electrónicas.</p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg"
                                             style={{backgroundImage: 'url(' + bg_6 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'center'}}>
                                            <h1 style={{
                                                fontFamily: 'Arial, sans-serif',
                                                fontWeight: 'bold',
                                                fontSize: 'xx-large'
                                            }}>Consulta DNI, RUC y EsSalud</h1>
                                            <p style={{fontFamily: 'Arial, sans-serif', fontSize: 'large'}}>Consultas de
                                                reniec, sunat y essalud sin capcha.</p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fragment>
            :
            <Fragment>
                <div className="h-100">
                    <Row className="h-100 no-gutters">
                        <Col lg="7" md="12"
                             className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center">
                            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                                <div className="text-center">
                                    {/*<img src="/logo.png" alt="Logo"/>*/}
                                    <img style={{width: '50%', marginBottom: 30}} src="/new_logo.png" alt="Logo"/>
                                </div>
                                <h4>
                                    <div style={{fontWeight: "bold", fontSize: "xx-large", color: "black"}}>Bienvenido,</div>
                                    <span>Solo toma unos <span className="text-primary">segundos</span> crear su cuenta</span>
                                </h4>
                                <div>
                                    <Form>
                                        <Row form>
                                            <Col md={12}>
                                                <FormGroup style={{marginBottom: "0.8rem"}}>
                                                    <Label style={{fontWeight: "bold", fontSize: "medium"}}>
                                                        Nombres <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input type="text" name="name" value={user.name} onChange={handleChange}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup style={{marginBottom: "0.8rem"}}>
                                                    <Label style={{fontWeight: "bold", fontSize: "medium"}}>
                                                        Email <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input type="email" name="email" value={user.email}
                                                           onChange={handleChange}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label style={{fontWeight: "bold", fontSize: "medium"}}>
                                                        Teléfono de Contacto <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input type="text" name="telefono" value={user.telefono}
                                                           onChange={handleChange}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} style={{display: 'none'}}>
                                                <FormGroup>
                                                    <Label style={{fontWeight: "bold", fontSize: "medium"}}>
                                                        Apellido Paterno <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input type="text" name="apellido_paterno"
                                                           value={user.apellido_paterno}
                                                           onChange={handleChange}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} style={{display: 'none'}}>
                                                <FormGroup>
                                                    <Label style={{fontWeight: "bold", fontSize: "medium"}}>
                                                        Apelido Materno <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input type="text" name="apelido_materno"
                                                           value={user.apelido_materno}
                                                           onChange={handleChange}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label style={{fontWeight: "bold", fontSize: "medium"}}>
                                                        Contraseña <span className="text-danger">*</span>
                                                    </Label>
                                                    <div className="input-group">
                                                        <Input
                                                            type={showPassword ? "text" : "password"}
                                                            name="password"
                                                            value={user.password}
                                                            onChange={handleChange}
                                                        />
                                                        <div className="input-group-append">
                                                            <Button onClick={togglePasswordVisibility}>
                                                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label style={{fontWeight: "bold", fontSize: "medium"}}>
                                                        Confirmar Contraseña <span className="text-danger">*</span>
                                                    </Label>
                                                    <div className="input-group">
                                                        <Input
                                                            type={showConfirmPassword ? "text" : "password"}
                                                            name="confirm_password"
                                                            value={user.confirm_password}
                                                            onChange={handleChange}
                                                        />
                                                        <div className="input-group-append">
                                                            <Button onClick={toggleConfirmPasswordVisibility}>
                                                                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <div className="mt-4 d-flex align-items-center">
                                        <h5 className="mb-0">
                                            ¿Ya tienes una cuenta?{' '}
                                            <Link to="/login" className="text-primary">Login</Link>
                                        </h5>
                                        <div className="ml-auto">
                                            <LaddaButton className="btn btn-pill btn-primary"
                                                         loading={expRight} onClick={postRegister}
                                                         data-style={EXPAND_RIGHT}>
                                                <FontAwesomeIcon icon={faUserPlus}/> Crear cuenta
                                            </LaddaButton>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Col>
                        <Col lg="5" className="d-none d-lg-block">
                            <div className="slider-light">
                                <Slider  {...settings}>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{backgroundImage: 'url(' + bg_1 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'center'}}>
                                            <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 'xx-large' }}>Sistema automatizado de SIRE</h1>
                                            <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 'xx-large' }}>con API WEB de SUNAT</h1>
                                            <p style={{ fontFamily: 'Arial, sans-serif',  fontSize: 'large'}}>Módulo automatizado de SIRE para procesar, validar y enviar RVIE y RCE a SUNAT de
                                                forma simple, segura y automática mediante el API WEB del SIRE</p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{backgroundImage: 'url(' + bg_2 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'center'}}>
                                            <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 'xx-large' }}>Proceso Simplificado</h1>
                                            <p style={{ fontFamily: 'Arial, sans-serif',  fontSize: 'large'}}>Proceso de validación y comparación entre lo contable y lo propuesto por SUNAT; asícomo, el envío de los registros en simples pasos.</p>
                                            <p style={{ fontFamily: 'Arial, sans-serif',  fontSize: 'large'}}>(Solo requiere subir TXT de tu sistema contable)</p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{backgroundImage: 'url(' + bg_3 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'center'}}>
                                            <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 'xx-large' }}>Decide basado en la comparación contable</h1>
                                            <p style={{ fontFamily: 'Arial, sans-serif',  fontSize: 'large'}}>Confronta tus datos con la propuesta de SUNAT</p>
                                            <p style={{ fontFamily: 'Arial, sans-serif',  fontSize: 'large'}}>Determina diferencias entre el detalle de los registros contables y los propuestos.</p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-left align-items-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{backgroundImage: 'url(' + bg_4 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'center'}}>
                                            <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 'xx-large' }}>Envía directo a SUNAT y descarga tus constancias</h1>
                                            <p style={{ fontFamily: 'Arial, sans-serif',  fontSize: 'large'}}>Genera y/o envía tus registros contables directo a SUNAT, descarga tus constancias y consulta tu historial de registros anotados.</p>

                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-left align-items-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{backgroundImage: 'url(' + bg_5 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'center'}}>
                                            <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 'xx-large' }}>Consulta de Validez de CPE</h1>
                                            <p style={{ fontFamily: 'Arial, sans-serif',  fontSize: 'large'}}>Consulte la validez de boletas, facturas y sus respectivas notas electrónicas.</p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg" style={{backgroundImage: 'url(' + bg_6 + ')', backgroundSize: 'cover'}}/>
                                        <div className="slider-content" style={{textAlign: 'center'}}>
                                            <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 'xx-large' }}>Consulta DNI, RUC y EsSalud</h1>
                                            <p style={{ fontFamily: 'Arial, sans-serif',  fontSize: 'large'}}>Consultas de reniec, sunat y essalud sin capcha.</p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fragment>
    )
}

export default Register